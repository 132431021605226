function slider() {
    const feedbackSwiper = new Swiper(".slider__swiper", {
        // slidesPerView: 3,
        observer: true,
        observeSlideChildren: true,
        updateOnWindowResize: true,
        observeParents: true,
        speed: 500,
        // autoplay: {
        //     delay: 3500,
        //     disableOnInteraction: false,
        //   },
          navigation: {
            nextEl: '.slider__btn.next',
            prevEl: '.slider__btn.prev',
          },
        breakpoints: {
            576: {
                // spaceBetween: "6%",
            },
            992: {
                slidesPerView: 1,
                spaceBetween: "4%",
            },
            1281: {
                slidesPerView: 4,

            },
            1641: {
                slidesPerView: 4,
            },
        }
    })
}

module.exports = slider;